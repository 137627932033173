import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

export const OverlayContext = createContext()

export function OverlayProvider({ children }) {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const [id, setId] = useState(0);
    const [email, setEmail] = useState('');
    const [bool, setBool] = useState(false);
    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(null);
    console.log(data);
    // const {token, http, transactionApi, logout } = Api();
    const [transaction, setTransaction] = useState({
        username: '',
        login_date_time: 0,
        logout_date_time: 0,
        flag: null
    })

    const [latestTransaction, setLatestTransaction] = useState(0);

    const stringifyTrans = JSON.stringify(transaction)
    if (sessionStorage.getItem('transaction') === null) {
        sessionStorage.setItem('transaction', stringifyTrans);

    }
    const flagVal = JSON.parse(sessionStorage.getItem('transaction'))
    if (transaction.username === "" && transaction.login_date_time === 0 && flagVal.flag === 0) {
        const transactionReload = JSON.parse(sessionStorage.getItem('transaction'))
        console.log(transactionReload);
        
        setTransaction(transactionReload);
        
    }

    console.log(JSON.stringify(transaction));

    console.log(transaction);
    
    console.log(bool);

    const [showOverLay, setShowOverlay] = useState(false)
    const [start, setStart] = useState(false);
    const [finish, setFinish] = useState(true);
    const [timer, setTimer] = useState(600);
    const [timeInterval, setTimeInterval] = useState(null);
    const scoreDataArr = [
        { bgColor: 'rgb(0, 148, 32)', text: 'Total Correct' },
        { bgColor: 'rgb(201, 0, 0)', text: 'Total Wrong' },
        { bgColor: 'rgb(236, 152, 26)', text: 'Total Skip', value: 0 },
        { bgColor: '#133382', text: 'Bookmark', value: 0 },
    ]

    const navigate = useNavigate();

    const location = useLocation();

    if (location.key === 'default') {
        navigate('/')
    }
    useEffect(() => {
        axios.get('https://skillupitacademy.com/backend/public/api/auth/question')
            .then(response => {
                setData(response.data.questions);
                console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);
    const [score, setScore] = useState(scoreDataArr);
    const toggle = () => {
        setShowOverlay(true)
    }
    const close = () => {
        setShowOverlay(false);
    }
    const handleStart = () => {
        setStart(!start);
        setTimeInterval(setInterval(() => {
            setTimer((prev) => prev - 1);
        }, 1000));
    }
    useEffect(() => {
        if (timer === 0) {
            clearInterval(timeInterval);
            setFinish(!finish);
            handleSubmit()
            setData(data);
        }
    }, [timer]);

    const pauseTimer = () => {
        clearInterval(timeInterval);
    }
    const handleFinish = () => {
        setFinish(!finish);
        pauseTimer()
    }
    const handleTryAgain = () => {
        const reset = score.map((item) => {
            if (item.text === 'Total Skip') {
                return { ...item, value: 0 }
            }
            if (item.text === 'Bookmark') {
                return { ...item, value: 0 }
            }
            return item
        })
        setScore(reset)
        setStart(!start);
        setFinish(!finish);
        setTimer(600)
    }
    const handleScoreCount = (data) => {
        const count = data.filter((item) => {
            return item.bookmark === true;
        })
        const countSkip = data.filter((item) => {
            return item.isChecked !== true;
        })
        const updatedScore = score.map((item) => {
            if (item.text === 'Bookmark') {
                const newBookCount = { ...item, value: count.length }
                return newBookCount
            }
            if (item.text === 'Total Skip') {
                const newSkipCount = { ...item, value: countSkip.length }
                return newSkipCount
            }
            return item
        })
        setScore(updatedScore)
    }
    const handleSubmit = () => {
        handleScoreCount(data);
        handleFinish()
        setData(data);
    }
    const minutes = Math.floor((timer % 3600) / 60);
    const seconds = timer % 60;

    useEffect(() => {
        if (flagVal.flag === 0 && location.pathname === '/pages/form') {
            navigate('/pages/dashboard')
            if (window.confirm('You will be logged out')) {
                setBool(true);
            } 
        }
    }, [navigate])
    return (
        <OverlayContext.Provider
            value={{
                showOverLay, start, minutes, seconds, finish, score, data, transaction, latestTransaction, formData, 
                bool, id, email, flag,flagVal,setBool, setFormData, setTransaction, setLatestTransaction, setData,
                setScore, handleStart,toggle, close, handleFinish,handleTryAgain, handleSubmit, handleScoreCount, 
                setFlag
            }}
        >
            {children}
        </OverlayContext.Provider>
    )
}
