import React, { useContext, useState } from 'react'
import QuizSingleQuest from './QuizSingleQuest';
import QuizIndex from './QuizIndex';
import { OverlayContext } from '../context/OverlayContext';

export default function QuizQuestions() {
    const { data, setData, handleSubmit } = useContext(OverlayContext);
    
    const [selectedOptions, setSelectedOptions] = useState({});
    const [question, setQuestion] = useState(1);
    const [singleData] = data.filter((item) => {
        return item.id === question;
    });
    const handleBookmark = () => {
        const questId = singleData.id;
        const updatedBookMark = data.map((item) => {
            if (questId === item.id) {
                return { ...item, bookmark: !item.bookmark }
            }
            return item
        })
        setData(updatedBookMark)
        const updateBg = updatedBookMark.map((item) => {
            if (item.id === questId) {
                const newBg = { ...item, backgroundColor: item.bookmark ? '#133382' : '', color: item.bookmark ? '#e5e5e5' : '' }
                return newBg;
            }
            return item;
        })
        setData(updateBg);
    }
    console.log(singleData);
    const handleSkipped = (e) => {
        const questId = singleData.id;
        const updatedSelectedOptions = { ...selectedOptions, [questId]: e.target.value };
        setSelectedOptions(updatedSelectedOptions);

        const updatedData = data.map((item) => {
            if (item.id === questId) {
                return { ...item, isChecked: true };
            }
            return item;
        });
        setData(updatedData);
    };
    const handleQuestions = (e) => {
        const { value } = e.target;
        setQuestion(parseInt(value));
    }
    const handleNext = () => {
        const next = question + 1;
        setQuestion(next);
    }
    return (
        <>
            <QuizSingleQuest
                singleData={singleData}
                selectedOptions={selectedOptions[singleData.id]}
                handleSkipped={handleSkipped}
            />
            <div className='my-4 flex '>
                <button onClick={handleNext} className='inline-block px-4 py-2 ml-4 my-2 text-white bg-pink rounded'>
                    Next
                </button>
                <button onClick={handleBookmark} className='inline-block px-4 py-2 ml-4 my-2 text-white bg-blue rounded'>
                    BookMark
                </button>
                <button onClick={handleSubmit} className='inline-block px-4 py-2 ml-4 my-2 text-white bg-blue rounded'>
                    Submit
                </button>
                {/* <button onClick={submit}>click</button> */}
            </div>
            <QuizIndex data={data} handleQuestions={handleQuestions} question={question} />
        </>
    )
}
