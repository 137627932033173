import Attendance from '../components/Attendance'
import DashSideBar from '../components/DashSideBar'
import Greetings from '../components/Greetings'
import SubjectCompletion from '../components/SubjectCompletion'
import Activity from '../components/Activity'
import BasedOn from '../components/BasedOn'
import Calender from '../components/Calender'
import LogoutAnchor from '../components/LogoutAnchor'
import { OverlayContext } from '../context/OverlayContext'
import useLogout from '../hooks/useLogout'
import { useContext } from 'react'

export default function Dashboard() {
    const handleLogout = useLogout();
    const {bool, setBool } = useContext(OverlayContext);
    if(JSON.parse(sessionStorage.getItem('transaction'))){
        console.log(false);
    }
    if (bool) {
        handleLogout();
        setBool(false)
    }
    return (
        <>
            <LogoutAnchor />
            <div className='bg-[#fafbfd]'>
                <DashSideBar />
                <div className='md:ml-[260px]'>
                    <div className='container-full mx-auto'>
                        <section className='px-5 pt-2.5'>
                            <Greetings />
                            <div className='flex flex-wrap'>
                                <SubjectCompletion />
                                <Attendance />
                            </div>
                            <div className='flex flex-wrap flex-col lg:flex-row '>
                                <Calender />
                                <div className='flex flex-wrap flex-col justify-evenly lg:w-1/2 ' >
                                    <Activity />
                                    <BasedOn />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}
