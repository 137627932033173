import { useState } from 'react'
import SignIn from '../components/SignIn'
import SignUp from '../components/SignUp'


export default function Form() {
    const [rotate, setRotate] = useState(0);

    const handleRotation = () => {
        setRotate(rotate + 180);
    }

    return (
        <div className='w-full my-0 mx-auto'>
            <div className='w-full min-h-screen relative flex flex-wrap justify-center items-center p-[15px] bg-no-repeat bg-center bg-cover bg-gradient-to-r from-[#133282] to-[#f54b8a]'>
                <div className='form-container w-[400px] relative'>
                    <div className='inner-form' style={{ transform: `rotateY(${rotate}deg)` }}>
                        <SignIn handleRotation={handleRotation} />
                        <SignUp handleRotation={handleRotation} />
                    </div>
                </div>
            </div>
        </div>
    )
}