import React, { useContext, useState } from 'react'
import logo from '../img/PROFILE_SKILLUP_LOGO-02.jpg'
import { Link } from 'react-router-dom'
import Button4 from '../components/Button4'
import Api from '../API/Api.js'
import { OverlayContext } from '../context/OverlayContext'
import Time from './Time'

export default function SignUp({ handleRotation }) {
    const [visible, setVisible] = useState(false);
    const { transaction, setTransaction } = useContext(OverlayContext);
    const { http, setToken } = Api();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
    });
    const [error, setError] = useState('');
    const icons = [
        {
            icon: "facebook-f",
            color: "#3b5998",
        },
        {
            icon: "twitter",
            color: "#1da1f2",
        },
        {
            icon: "facebook-f",
            color: "#ea4335",
        }
    ];
    const toggle = () => {
        setVisible(!visible);
    }
    const saveStudent = async (e) => {
        e.preventDefault();
        const email = formData.email;
        setTransaction({ ...transaction, username: email, login_date_time: Time(), flag: 0 })
        const transactionData = { ...transaction, username: email, login_date_time: Time(), flag: 0 }
        try {
            await http.post('/auth/register', {
                ...formData,
                ...transactionData
            }).then((res) => {
                console.log(res);
                const currentUser = { newId: res.data.track.transaction.id, newEmail: res.data.track.transaction.username }
                const stringifyCurrUser = JSON.stringify(currentUser);
                sessionStorage.setItem('transaction', JSON.stringify(transactionData));
                sessionStorage.setItem('currentUser', stringifyCurrUser)
                setToken(res.data.user, res.data.token);
                setFormData({
                    name: "",
                    email: "",
                    password: "",
                })
            });
        } catch (error) {
            // console.log(error);
            const errorValue =JSON.parse(error.response.data);
            setError(Object.values(errorValue)[0]);
            // alert(errorValue)
        }
    }

    console.log(error);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }
    return (
        <div className='form-back absolute top-0 bottom-0 left-0 right-0 bg-white rounded-[10px] overflow-hidden pl-[45px] pr-[45px] pt-[15px] pb-[34px] max-sm:pl-[15px] max-sm:pr-[15px] z-[100]'>
            <form className='w-full' onSubmit={saveStudent}>
                <div className='max-w-20 mx-auto mb-2'>
                    <Link to='/'>
                        <img src={logo} alt="logo" />
                    </Link>
                </div>
                <span className=' block text-[29px] text-[#333] leading-5 text-center font-bold pb-[29px]'>
                    <span className='text-blue'>Create</span>
                    <span className='text-pink'> Account</span>
                </span>
                <div className='relative w-full border-b-2 border-b-solid border-b-[#d9d9d9] mb-[8px]'>
                    <label htmlFor='name' className='font-regular text-xs text-[#333] leading-6 pl-[7px]'>
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Type your FullName"
                        required
                        className="input100 group font-normal text-sm text-[#333] leading-5 block w-full h-[30px] pl-[33px] pr-[7px] focus:outline-none "
                    />
                    <span className="focus-input100 absolute block w-full h-full top-0 left-0 pointer-events-none"></span>
                    <div className="icon-container absolute top-7 left-0">
                        <div className="inner-box">
                            <div className="icon-front">
                                <i className="fa-solid fa-user text-[#adadad] text-sm" ></i>
                            </div>
                            <div className="icon-back">
                                <i className="fa-solid fa-user text-[#adadad]  text-sm" ></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='relative w-full border-b-2 border-b-solid border-b-[#d9d9d9] mb-[8px]'>
                    <label htmlFor='emailSignUp' className='font-regular text-xs text-[#333] leading-6 pl-[7px]'>
                        Email
                    </label>
                    <input
                        type="email"
                        id="emailSignUp"
                        value={formData.email}
                        name="email"
                        onChange={handleChange}
                        placeholder="Type your email"
                        required
                        className="input100 group font-normal text-sm text-[#333] leading-5 block w-full h-[30px] pl-[33px] pr-[7px] focus:outline-none "
                    />
                    <span className="focus-input100 absolute block w-full h-full top-0 left-0 pointer-events-none"></span>
                    <div className="icon-container absolute top-7 left-0">
                        <div className="inner-box">
                            <div className="icon-front">
                                <i className="fa-solid fa-envelope text-[#adadad] text-sm" ></i>
                            </div>
                            <div className="icon-back">
                                <i className="fa-solid fa-envelope text-[#adadad]  text-sm" ></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='relative w-full border-b-2 border-b-solid border-b-[#d9d9d9] mb-[10px]'>
                    <label htmlFor='passwordSignUp' className='font-regular text-xs text-[#333] leading-6 pl-[7px]'>
                        Password
                    </label>
                    <input
                        type={visible ? "text" : "password"}
                        id="passwordSignUp"
                        name="password"
                        onChange={handleChange}
                        value={formData.password}
                        placeholder="Type your password"
                        required
                        className="input100 group font-medium text-sm text-[#333] leading-5 block w-full h-[30px] pl-[33px] pr-[7px] focus:outline-none "
                    />
                    <span className="focus-input100 absolute block w-full h-full top-0 left-0 pointer-events-none"></span>
                    <div className="icon-container absolute top-7 left-0">
                        <div className="inner-box">
                            <div className="icon-front">
                                <i className="fa-solid fa-lock text-[#adadad] text-sm" ></i>
                            </div>
                            <div className="icon-back">
                                <i className="fa-solid fa-lock text-[#adadad] text-sm" ></i>
                            </div>
                        </div>
                    </div>
                    <span className="float-right mr-[12px] -mt-[28px] relative  text-[#555]" onClick={toggle}>
                        {visible ? <i className="fa-solid fa-eye-slash text-xs"></i> : <i className="fa-solid fa-eye text-sm "></i>}
                    </span>
                </div>
                {error ?
                    <div className='text-center font-bold text-blue pb-3'>
                        {error}
                    </div>
                    :
                    <div className='text-center text-slate-500 h-8'>

                    </div>
                }
                <div className="flex flex-wrap justify-center ">
                    <Button4 text="Sign Up" />
                </div>
                <div className="font-normal text-sm leading-6 text-[#666] text-center pt-[10px] pb-2">
                    <span>Or Sign Up Using</span>
                </div>
                <div className="group flex justify-center items-center">
                    {icons.map((i, idx) => (
                        <a key={idx} style={{ backgroundColor: i.color }} className="login100-social-item text-[15px] text-white flex justify-center items-center w-[40px] h-[40px] rounded-[50%] m-[5px] group-hover:bg-[#333] cursor-pointer transition duration-500" >
                            <i className={`fa-brands fa-${i.icon}`}></i>
                        </a>
                    ))}
                </div>
                <div className="text-center pt-[10px] justify-center">
                    <span className='font-normal text-sm leading-6 text-[#666]'>Already have an account?
                        <a className="sign group font-bold text-sm leading-6 cursor-pointer transition-all duration-300 " onClick={handleRotation} >
                            <span className='text-blue group-hover:text-pink transition-all duration-300 z-50'> Sign</span>
                            <span className='text-pink group-hover:text-blue transition-all duration-300 z-50 '> in</span>
                        </a>
                    </span>
                </div>
            </form>
        </div>
    )
}
