import React from 'react'

export default function Greetings() {
    const userInfo = JSON.parse(sessionStorage.getItem('user'));

    console.log(userInfo.name);
    return (
        <div className='w-full px-2.5'>
            <div className='mb-5 w-full rounded-[10px] bg-[#e1eaf9]'>
                <div className='py-5'>
                    <div className='p-[30px]'>
                        <div className='flex flex-wrap'>
                            <div className='w-full px-2.5'>
                                <h2 className='text-blue text-3xl my-2.5'>Welcome back <strong>{userInfo.name}!</strong></h2>
                                <p className='text-slate-800 text-base my-2.5 '>You have completed 80% of the task</p>
                                <p className='text-slate-800 text-base my-2.5'>Progress very good!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
