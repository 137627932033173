import { useEffect, useRef } from 'react';
import useLogout from './useLogout';

const time = 5 * 60;
export const useIdleLogout = (idleTime = time) => {
    const idleRef = useRef(0);
    const handleLogout = useLogout();
    // const { handleLogout } = useContext(OverlayContext);
    useEffect(() => {
        const timerIncrement = () => {
            idleRef.current += 1;
            if (idleRef.current > idleTime) {
                handleLogout();
                clearInterval(idleInterval);
            }
        };

        const idleInterval = setInterval(timerIncrement, 1000);

        const resetIdleRef = () => {
            idleRef.current = 0;
        };

        document.body.addEventListener('mousemove', resetIdleRef);
        document.body.addEventListener('mousedown', resetIdleRef);
        document.body.addEventListener('keypress', resetIdleRef);
        document.body.addEventListener('click', resetIdleRef);
        document.body.addEventListener('scroll', resetIdleRef);

        return () => {
            document.body.removeEventListener('mousemove', resetIdleRef);
            document.body.removeEventListener('mousedown', resetIdleRef);
            document.body.removeEventListener('keypress', resetIdleRef);
            document.body.removeEventListener('click', resetIdleRef);
            document.body.removeEventListener('scroll', resetIdleRef);
            clearInterval(idleInterval);
        };
    }, [handleLogout, idleTime]);
};