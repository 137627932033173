import React, { useContext } from 'react'
import Api from '../API/Api';
import { OverlayContext } from '../context/OverlayContext';
import Time from '../components/Time';

const useLogout = () => {
    const { token, http, transactionApi, logout } = Api();
    const { transaction, setTransaction, setFormData } = useContext(OverlayContext)

    // console.log(id);
    const handleLogout = async () => {
        try {
            const session = JSON.parse(sessionStorage.getItem('currentUser'));
            const id = session.newId;
            const email = session.newEmail;
            const updatedTransaction = { ...transaction, logout_date_time: Time(), flag: 1 };
            const param = { id: id, email: email }
            // alert(JSON.stringify(param));
            // await transactionApi.put(`/auth/logoutUpdate`, { param, updatedTransaction }).then((res) => {
            //     console.log(res);
            // });
            await http.post('/auth/logout', { param, updatedTransaction }).then((res) => {
                console.log(res);
            })
        } catch (e) {
            console.log(e);
        }
        finally {
            if (token !== undefined) {
                logout()
                setTransaction({
                    username: '',
                    login_date_time: 0,
                    logout_date_time: 0,
                    flag: null
                })
                setFormData({
                    email: "",
                    password: "",
                })
            }
        }
    }
   
    return handleLogout
}

export default useLogout

